import { createRouter, createWebHistory, useRoute } from 'vue-router'
import CommentListAll from './views/CommentListAll.vue'
import CommentListNews from './views/CommentListNews.vue'
import DataFaceAll from './views/DataFaceAll.vue'
import DataFaceHome from './views/DataFaceHome.vue'
import DataFaceOne from './views/DataFaceOne.vue'
import DataList from './views/DataList.vue'
import FansList from './views/FansList.vue'
import FansData from './views/FansData.vue'
import Home from './views/Home.vue'
import PostImageArr from './views/PostImageArr.vue'
import PostImageText from './views/PostImageText.vue'
import PostProject from './views/PostProject.vue'
import PostVideo from './views/PostVideo.vue'
import Settings from './views/Settings.vue'
import NewsData from './views/NewsData.vue'
import Login from './views/Login.vue'
import store from './store'
import axios from 'axios'
const routerHistory = createWebHistory()
const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { redirectAlreadyLogin: true }
    },
    {
      // 评论管理 全部
      path: '/CommentListAll',
      name: 'CommentListAll',
      component: CommentListAll,
      meta: { requiredLogin: true }
    },
    {
      // 粉丝数据
      path: '/FansData',
      name: 'FansData',
      component: FansData,
      meta: { requiredLogin: true }
    },
    {
      // 评论管理 新闻
      path: '/CommentListNews',
      name: 'CommentListNews',
      component: CommentListNews,
      meta: { requiredLogin: true }
    },
    {
      path: '/DataFaceAll',
      name: 'DataFaceAll',
      component: DataFaceAll,
      meta: { requiredLogin: true }
    },
    {
      path: '/DataFaceHome',
      name: 'DataFaceHome',
      component: DataFaceHome,
      meta: { requiredLogin: true }
    },
    {
      path: '/DataFaceOne',
      name: 'DataFaceOne',
      component: DataFaceOne,
      meta: { requiredLogin: true }
    },
    {
      path: '/DataList',
      name: 'DataList',
      component: DataList,
      meta: { requiredLogin: true }
    },
    {
      path: '/FansList',
      name: 'FansList',
      component: FansList,
      meta: { requiredLogin: true }
    },
    {
      path: '/PostImageArr',
      name: 'PostImageArr',
      component: PostImageArr,
      meta: { requiredLogin: true }
    },
    {
      path: '/PostImageText',
      name: 'PostImageText',
      component: PostImageText,
      meta: { requiredLogin: true }
    },
    {
      path: '/PostProject',
      name: 'PostProject',
      component: PostProject,
      meta: { requiredLogin: true }
    },
    {
      path: '/PostVideo',
      name: 'PostVideo',
      component: PostVideo,
      meta: { requiredLogin: true }
    },
    {
      path: '/Settings',
      name: 'Settings',
      component: Settings,
      meta: { requiredLogin: true }
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { requiredLogin: true }
    },
    {
      path: '/NewsData',
      name: 'NewsData',
      component: NewsData,
      meta: { requiredLogin: true }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const { user, token } = store.state

  const { requiredLogin, redirectAlreadyLogin } = to.meta

  document.title = '发展中国'

  if (!user.isLogin) {
    if (token) {
      console.log('token if')

      axios.defaults.headers.token = token
      next()
    } else {
      console.log('token else')

      if (localStorage.getItem('userInfo') !== null) {
        console.log('token else if')

        store.dispatch('setUserInfo').then(() => {
          if (redirectAlreadyLogin) {
            console.log('token else if if')

            next('/')
          } else {
            console.log('token else if if else')

            next()
          }
        })
      } else {
        if (requiredLogin) {
          window.location.href = '/PPPing/html/index.html'
          // next('login')
        } else {
          console.log('token else')
          next()
        }
      }
    }
  } else {
    if (redirectAlreadyLogin) {
      next('/')
    } else {
      next()
    }
  }
})

export default router
